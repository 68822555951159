/* ----------------------------------------------------------------
   Global Styles
-----------------------------------------------------------------*/
body,
html {
    margin: 0;
    padding: 0;
}

body {
    background: $color_white;
    color: $color_text-screen;
    font-family: $font__main;
    line-height: 1.5;
    font-weight: normal;
    font-size: em(16);

    @media(max-width: $lg-max) {
        font-size: em(15);
    }

    @media(max-width: $md-max) {
        font-size: em(14);
    }

    @media(max-width: $sm-max) {
        font-size: em(13);
    }
}

h1,
h2,
h3,
h4 {
    font-family: $font__second;
    color: $color_text_heading;
    font-weight: 800;
}

h1 {
    font-size: em(52);
    line-height: 1.35;
    letter-spacing: -1.3px;
}

h2 {
    font-size: em(36);
    line-height: 0.86;
    letter-spacing: em(-0.9);

    @media(max-width: $md-max) {
        font-size: em(32);
    }
}

h3 {
    font-size: em(24);
    letter-spacing: em(-0.6);

    @media(max-width: $md-max) {
        font-size: em(22);
    }

    @media(max-width: $xs-max) {
        font-size: em(24);
    }
}

h4 {
    font-size: em(18);
    letter-spacing: em(-0.5);
}

a {
    text-decoration: none;

    &:hover,
    &:active {
        outline: 0;
    }
}



.btn {
    background-color: $color_aqua-marine;
    color: $color_white;
    display: inline-block;
    font-family: 'Overpass', sans-serif;
    font-weight: bold;
    border-radius: 6px;
    cursor: pointer;

    font-size: em(18);
    letter-spacing: em(-0.55);
    padding: em(14) em(25) em(10) em(25);

    transition: all .3s ease-out;

    &:hover {
        background-color: $color_dark_blue;
    }

}

// Home hero section
// This will not appear in the CSS output
section.home_hero {

    background-image: url(../images/home-section.svg);
    background-position: top center;
    background-size: 140% 130%;
    background-repeat: no-repeat;
    position: relative;

    .hero_head {
        position: relative;
        text-align: center;
        padding: 3em 0;

        img {
            width: em(136);
            margin: 0 auto;

            @media(max-width: $xs-max) {
                width: em(120);
            }
        }
        @include bp(phone) {
            padding: 1em 0;
        }
    }

    .hero_cont {
        display: block;
        padding: 5em 0;

        @include bp(phone) {

             padding: 2em 0;
            width: 77%;
        }
        h1 {
            @media(max-width: $sd-max) {
                font-size: em(46);
                line-height: 1.25;
            }

            @media(max-width: $xxs-max) {
                font-size: em(40);
            }

            @media(max-width: $smallest) {
                font-size: em(36);

            }


            span {
                color: $color_aqua-marine;
            }
        }

        p.hero_description {
            font-size: em(18);
            line-height: 2.11;
            letter-spacing: em(0.1);
            margin-bottom: em(27);
        }

        a.btn {
            margin-top: em(20);
        }
    }

    .hero_foot {
        position: relative;
        text-align: center;
        padding-top: 5em;

        a {
            width: em(150);
            height: em(74);
            background-image: url(../images/webiz_oval.svg);
            background-size: 100%;
            margin: 0 auto;
            display: flex;
            flex-flow: column wrap;
            align-items: center;
            justify-content: center;
            color: $color_text_heading;
            img {
                width: em(16);
                transition: all .4s ease;
            }

            &:hover {

                img {
                    margin-top: em(20);
                }
            }
        }
    }
}

.about-bg {

}

// Home hero section
// This will not appear in the CSS output
section.about_section {
    position: relative;
    background-image: url('../images/background/webiz-about-left.svg');
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 35% 50%,cover;
   

    .about_cont {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: center;
    }

    .left {
        position: relative;
    }

    .right {
        @include bp(desktop) {
            width: 40%;
            padding: em(160) 0 em(160) em(100);            
        }
        @include bp(phone) {
            // text-align: center;
        }

    }

    h2 {
        margin-bottom: em(5);
    }

    p {
        margin-bottom: em(40);
    }

    svg {
        transition: transform .2s ease-in-out;
    }

    .shape_info {
        -webkit-font-smoothing: subpixel-antialiased;
        transform: translateZ(0);
        backface-visibility: hidden;

        transition: all .4s ease;
        -webkit-backface-visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
        width: 90%;
        height: 90%;


        img {
            margin-bottom: rem(4);
        }

        h3 {

            line-height: em(17.5);
            margin: em(5) 0 0 0;
            font-size: em(22);
        }

        p {

            font-size: em(14);
            line-height: 1.29;
            letter-spacing: em(-0.2);
            margin: em(5) 0 0 0;

            &.hidden {
                opacity: 0;
                height: 0;
                overflow: hidden;
            }

            &.visible {
                opacity: 1;
                height: 1;
                overflow: visible;
                transition: opacity .5s linear;
            }
        }
    }

    .shape_section {
        position: absolute;
        transition: all .4s ease;

        backface-visibility: hidden;
        transform: translateZ(0);
        -webkit-font-smoothing: subpixel-antialiased;

        @include bp( tablet ) {
            position: relative;
            display: inline-block;
            width: 45%;
            margin: 2em 0;
            .shape_info {
               width: 50%;  
            }
            
            .shape_design.shape_section svg {
                transform: scale(1) rotate(0deg);
            }
           
            svg {
                width: 96%;
                transform: scale(1.2) rotate(0deg);
            }
            p {
                &,&.hidden {
                     opacity: 1;
                     height: auto;
                    visibility: visible;
                    overflow: visible;
                    transition: opacity 450ms ease-in-out;
                }
                   
            }
            .qa_info {
                &.shape_info {
                    img {
                margin-top: 1em;
            } 
                }
            }
           
            &:hover {

                svg {
                    transform: scale(1.2) rotate(0deg);
                }

                p {
                    opacity: 1;
                    visibility: visible;
                    overflow: visible;
                    transition: opacity 450ms ease-in-out;
                }
            }
        }

        @include bp( phone ) {
            position: relative;
            display: block;
            margin: 2em 0;
            .shape_info {
               width: 50%;  
            }
            
            .shape_design.shape_section svg {
                transform: scale(1) rotate(0deg);
            }
           
            svg {
                width: 96%;
                transform: scale(1.2) rotate(0deg);
            }
            p {
                &,&.hidden {
                     opacity: 1;
                     height: auto;
                    visibility: visible;
                    overflow: visible;
                    transition: opacity 450ms ease-in-out;
                }
                   
            }
            .qa_info {
                &.shape_info {
                    img {
                margin-top: 1em;
            } 
                }
            }
           
            &:hover {

                svg {
                    transform: scale(1.2) rotate(0deg);
                }

                p {
                    opacity: 1;
                    visibility: visible;
                    overflow: visible;
                    transition: opacity 450ms ease-in-out;
                }
            }
        }
        // End mobile
        
        p {
            opacity: 0;
            height: 0;
            overflow: hidden;
        }

        &:hover {

            -webkit-backface-visibility: hidden;
            cursor: pointer;
            z-index: 1000;

            svg {
                transform: scale(1.35) rotate(-10deg);
                @include bp(phone) {
                    transform: scale(1.2) rotate(0deg);
                }
            }

            p {
                opacity: 1;
                visibility: visible;
                overflow: visible;
                transition: opacity 450ms ease-in-out;
            }

            .shape_info {

                //transform: scale(0.72);
            }

            .maintanence_info.shape_info {
                img {
                    margin-top: em(30);
                }
            }

            .dev_info.shape_info {
                img {
                    margin-top: em(20);
                }
            }

            .qa_info.shape_info {
                img {
                    margin-top: em(0);
                }
            }

            .pm_info.shape_info {
                img {
                    margin-top: em(20);
                }
            }

            .design_info.shape_info {
                img {
                    margin-top: em(40);
                }
            }
        }

        .maintanence_info.shape_info {
            left: em(10);
            img {
                width: rem(80);
                margin-top: em(60);
                transition: all .4s ease;
                 @include bp(phone) {
                 margin-top: 2em;
            }
            }
           
        }

        .dev_info.shape_info {
            left: em(10);

            img {
                width: rem(70);
                margin-top: em(50);
                transition: all .4s ease;
                  @include bp(phone) {
                 margin-top: 2em;
            }
            }
           
        }

        .qa_info.shape_info {
            img {
                width: rem(75);
                margin-top: em(50);
                transition: all .4s ease;
                    @include bp(phone) {
                 margin-top: 2em;
            }
            }
         
        }

        .pm_info.shape_info {

            img {
                width: rem(65);
                margin-top: em(35);
                transition: all .4s ease;
                 @include bp(phone) {
                 margin-top: 2em;
            }
            }
            
        }

        .design_info.shape_info {
            img {
                width: rem(75);
                margin-top: em(75);
                transition: all .4s ease;
                 @include bp(phone) {
                 margin-top: 2em;
            }
            }

        }
    }

    .shape_design.shape_section:hover svg {
        transform: scale(1.35) rotate(30deg);
    }

    .shape_maintanence {
        
        @include bp(desktop) {
           right: 0;
            top: 10%; 
        }
        
    }

    .shape_dev {
        @include bp(desktop) {
        right: 39%;
        top: 8%;
        }
    }

    .shape_qa {
        @include bp(desktop) {
            right: 21%;
            top: 47%;
        }
        svg {
            img {
                width: rem(56);
                margin-bottom: rem(4);
            }
        }
    }

    .shape_pm {
        @include bp(desktop) {
            right: 61%;
            top: 45%;
        }
        svg {
            img {
                width: rem(54);
                margin-bottom: 0;
            }
        }
    }

    .shape_design {
        @include bp(desktop) {
            right: 42%;
            top: 80%;
        }

        .shape_info {
            div {
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    // .about_bg {
    // position: absolute;
    // top: -369px;
    // z-index: -999;
    // background-image: url(../images/webiz_about_bg.svg);
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: 100% 100%,cover;
    // width: calc( 100% - 50px );
    // height: 100%;
    // padding-bottom: 61%;
    // }
}
/**
 * Big section background
 */
section.team-services-bg {
    background-image: url(../images/webiz_about_bg.svg),url(../images/background/webiz_team_services_bg.svg);
    background-position: top center,-360px 98%;
    background-size: 160% 73%,43% 50%;
    background-repeat: no-repeat,no-repeat;

    @include bp(desktop) {

    }
    @include bp(tablet) {
            background-size: 160% 35%,43% 50%;
    }
    @include bp(phone) {     
            background-position: top,-224px center;
            background-size: 145% 16%,92% 31%;
    }
}


/**
 * Core Team section
 */

.core-team_section {    
    
    background: url('../images/background/webiz_ourteam_bg_left.svg'), url('../images/background/freelancer-bg.svg');
    background-position: -150px 100%,980px 10%;
    background-size: 20% 38%,80% 70%;
    background-repeat: no-repeat,no-repeat;

    text-align: center;
    margin-top: 10em;
    margin-bottom: 8em;

    @include bp(tablet) {
         margin-top: 1em;
         margin-bottom: 1em;
    }

    @include bp(phone) {
         margin-top: 0;
         margin-bottom: 0;
    }

    ul.core-team-list {

        margin: em(60) 0 0 0;
        padding: 0;
        display: flex;
        flex-flow: row wrap;

        .team-info {
            position: relative;
        }

        li {
            list-style-type: none;
            flex: 1 0;

            &:hover {
                .social-connect {
                    display: block;
                }

                .image-shape {
                    svg {
                        transform: translateY(-10px) scale(1.5);
                        filter: url(#overlay);

                        @include bp(phone) {
                            transform: translateY(-10px) scale(1.2);
                        }

                        .overlay {

                            filter: alpha(opacity=70);
                            opacity: 0.7;

                            transition: opacity .4s ease-out;

                        }
                    }
                }

            }

            .image-shape {
                position: relative;
                backface-visibility: hidden;
                transition: all .4s ease;
                -webkit-backface-visibility: hidden;



                svg {
                    transition: all .4s ease;
                    backface-visibility: hidden;
                    transform: translateZ(0);
                    -webkit-font-smoothing: subpixel-antialiased;
                }

                img {
                    top: 0;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    text-align: center;
                    width: 100%;
                    height: 100%;
                }


            }

            .social-connect {
                display: none;
                position: absolute;
                z-index: 9999;
                top: 30%;
                left: 37%;
                @include bp(phone) {
                    left: 40%;
                }
                a {
                    margin-right: .5em;

                    svg {
                        width: 30px;
                        height: 30px;
                        transition: .2s all;

                        g,
                        path {
                            fill: white;
                        }
                    }

                    &:hover {
                        svg {
                            transform: translateY(-5px);
                            transition: .2s all;

                            g,
                            path {
                                fill: $color_text-screen;
                            }
                        }
                    }
                }
            }

            .details {
                h3 {
                    margin-bottom: 0;
                    line-height: 1.1;
                }

                p.position {
                    margin-top: 0;
                }
            }
        }

        // Support for compact section
        &.compact {
            li {
                h3 {
                    font-size: 1.3em;
                }
                .image-shape {


                    svg {
                        width: 115px;
                        height: 113px;
                    }


                }

                .social-connect {

                    top: 20%;
                    left: 37%;

                    a {
                        margin-right: .5em;

                        svg {
                            width: 20px;
                            height: 20px;
                            transition: .2s all;

                            g,
                            path {
                                fill: white;
                            }
                        }

                    }
                }
            }
        }
    }
}

// Background section between
// Team and SErvices
.about-service-bg {
    position: absolute;
    top: 0;
    left: 0;
    background: url('../images/background/webiz_team_services_bg.svg');
    background-size: cover;
}

// Services section
// This will not print in css.
.services_section {
    position: relative;



    .services-cont {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        @media(max-width: $md-max) {
            flex-wrap: wrap;
        }

        .col {
            padding: 0 em(20);

            &:nth-child(2) {
                @media (max-width: $md-max) {
                    width: 50%;
                    padding: 0;
                }

                @media (max-width: $xs-max) {
                    width: 100%;
                }
            }

            &:last-child {
                @media (max-width: $md-max) {
                    width: 50%;
                    padding: 0;
                }

                @media (max-width: $xs-max) {
                    width: 100%;
                }
            }
        }

        .services-info {
            padding-right: em(60);
            padding-left: 0;

            @media (max-width: $md-max) {
                width: 100%;
                text-align: center;
                padding-right: 0;
                margin-bottom: em(30);
            }



            h2 {
                margin: 0 0 em(5) 0;
            }
        }

        .service-container {
            margin: 0 auto;
            width: em(380);
            height: em(260);
            position: relative;
            border-radius: 8px;
            overflow: hidden;
            text-align: center;
            box-shadow: 0 0 14px 0 #d4e1f8;
            transition: transform 0.3s, opacity 0.2s;

            background: rgb(233, 238, 247);
            background: -moz-linear-gradient(left, rgba(233, 238, 247, 1) 0%, rgba(255, 255, 255, 1) 56%);
            background: -webkit-linear-gradient(left, rgba(233, 238, 247, 1) 0%, rgba(255, 255, 255, 1) 56%);
            background: linear-gradient(to right, rgba(233, 238, 247, 1) 0%, rgba(255, 255, 255, 1) 56%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e9eef7', endColorstr='#ffffff', GradientType=1);
            transform: translate3d(0,0,0);
            -webkit-transform: translate3d(0,0,0);

            @media(max-width: $lg-max) {
                width: em(340);
                height: em(230);
            }

            @media(max-width: $md-max) {
                width: em(420);
                height: em(260);
            }

            @media(max-width: $sm-max) {
                width: em(340);
                height: em(250);
            }

            @media(max-width: $smallest) {
                width: em(300);
                height: em(230);
            }

            h3 {
                margin-bottom: 0;
                margin-top: 2em;
                transition: all .4s ease;

                @media(max-width: $lg-max) {
                    margin-top: em(25);
                }

                @media(max-width: $md-max) {
                    margin-top: em(20);
                }
            }

            p {
                font-size: em(14);
                padding: 0 2em;
                line-height: 1.29;
                letter-spacing: em(-0.2);
                margin-top: em(10);
                transition: opacity .2s linear;
            }

            p.hover-info {
                opacity: 0;
                height: 0;
                padding: 0 1em;
                overflow: hidden;
                transition: opacity .2s linear;
            }

            .hidden {
                opacity: 0;
                height: 0;
                overflow: hidden;
                display: none;
                transition: opacity .2s linear;
            }

            p.hover-info.visible {
                opacity: 1;
                height: 1;
                overflow: visible;
                display: block;
                transition: opacity .6s linear;
            }

            .image {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                text-align: center;
                line-height: 0;
                transform: translateY(34%);
                transition: transform 0.4s, opacity 0.1s 0.3s;

                img {
                    margin: 0 auto;
                    width: em(260);
                    transition: all .4s ease;

                    @media(max-width: $md-max) {
                        width: em(320);
                    }

                    @media(max-width: $sm-max) {
                        width: em(270);
                    }

                    @media(max-width: $smallest) {
                        width: em(240);
                    }
                }

            }

            &:hover {

                transform: scale(1.10);
                background: $color_bright_blue;
                color: $color_white;
                box-shadow: 0 0 11px 0 rgba(174, 187, 227, 0.64);

                h3 {
                    color: $color_white;
                    transform: scale(0.8333);
                    margin-top: 1em;
                    transition: all .4s ease;
                }

                p {
                    transform: scale(0.8333);

                    &.info {
                        opacity: 0;
                        height: 0;
                        overflow: hidden;
                        display: none;
                        transition: opacity .2s linear;
                    }

                    &.hover-info {
                        opacity: 1;
                        height: 1;
                        overflow: visible;
                        display: block;
                        transition: opacity .6s linear;
                    }
                }

                img {
                    -webkit-backface-visibility: hidden; 
                    -ms-transform: translateZ(0); /* IE 9 */
                    -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
                    transform: translateZ(0);
                }

                .image {
                    transform: translateY(20px);
                    transition: transform 0.4s, opacity 0.1s;

                    img {
                        // width: em(270);
                        image-rendering: crisp-edges;
                        @media(max-width: $lg-max) {
                            width: em(300);
                        }

                        @media(max-width: $md-max) {
                            width: em(380);
                        }

                        @media(max-width: $sm-max) {
                            width: em(300);
                        }

                        @media(max-width: $smallest) {
                            width: em(280);
                        }
                    }
                }
            }
        }
        // Special case for mobile dev image
        .service-mobile-development {
            
            
            .image-pack {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                text-align: center;
                line-height: 0;
                transition: transform 0.3s, opacity 0.3s;
                -webkit-backface-visibility: hidden;
                transform: translateY(-120px) translateZ(0);
                img {
                    image-rendering: crisp-edges;
                }

                img.mobile-left {
                    position: absolute;
                    display: block;
                    width: 120px;
                    top: 30px;
                    left: 30px;
                    transition: all 0.4s;
                    -webkit-backface-visibility: hidden;
                    z-index: 5;
                }
                img.mobile-right {
                    position: absolute;
                    right: 0;
                    z-index: 5;
                    transition: all 0.4s;
                    -webkit-backface-visibility: hidden;
                    width: 120px;
                    top: 30px;
                    right: 30px;
                }
                img.mobile-center {
                    position: absolute;
                    margin: 0 auto;
                    width: 170px;
                    transition: width .4s, all 0.4s;
                    -webkit-backface-visibility: hidden;
                    right: 0;
                    left: 0;
                    z-index: 10;
                }
            }

            &:hover {
                .image-pack {
                    transition: transform 0.3s, opacity 0.3s;
                    transform: translateY(-160px) translateZ(0);

                     img.mobile-left {
                        transition: all 0.45s;
                        left: 10px;
                    }
                    img.mobile-center {
                        transition: width .4s;
                    }
                    img.mobile-right {
                        transition: all 0.45s;
                        right: 10px;
                    }
                }
                @include bp(phone) {
                    .image-pack {

                        transform: translateY(-135px) translateZ(0);
                    }
                }
            }
        }

        .service-website {
            margin-bottom: em(40);

        }

        .service-web-apps {}
    }

    @include bp(phone) {
        .col {
             width: 100%;
             .service-container {
                width: 80%;
                margin: 2em auto;
             }
             &.services-info {
                text-align: center;
             }
        }
       
    }
}

// Testimonials section
.testimonials_section {
    .logo-wrapper {
        text-align: center;

        svg {
            margin: 0 auto;
            width: 100%;
        }
    }

    .main_wrapper {
        padding-left: 4.46%;
        padding-right: 3.87%;
    }

    .testimonials_cont {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        text-align: center;

        @include bp(tablet) {
            flex-flow: column wrap;
            align-items: center;
            .testimonial-single {
                width: 100%;
            }
        }

        h2 {
            text-align: center;
            flex: 1 100%;
        }

        .testimonial-box {
            text-align: left;

            .inner {
                position: absolute;
                top: 18%;
                left: 22%;
                /* margin: 0 auto; */
                font-size: .9em;
                width: 70%;
                height: 91%;
            }

            @media(max-width: $sd-max) {
                font-size: em(19);
            }

            @media(max-width: $md-max) {
                font-size: em(21);
            }

            h3 {
                color: $color_white;
                margin: 0 0 10px 0;
            }

            h4 {
                margin: 0 0 10px 0;
            }

            p {
                color: $color_white;
                margin-top: 0;
            }

            .team-members {
                display: flex;
                flex-flow: row nowrap;
                align-items: stretch;

                >span {
                    color: $color_white;
                    opacity: 0.4;
                    font-size: em(14);
                    line-height: 1.29;
                    letter-spacing: em(-0.2);
                    position: relative;
                    top: em(11);
                }

                ul {
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    margin: 0;
                    padding: 0 0 0 em(15);
                    min-height: em(40);

                    li {
                        list-style-type: none;
                        z-index: 0;
                        
                        &:nth-child(2) {
                            position: relative;
                            left: em(-12);
                            z-index: 0;
                        }

                        &:last-child {
                            position: relative;
                            left: em(-20);
                            z-index: 0;
                        }

                        &:hover {
                            z-index: 100;
                        }

                        div.member-info {
                            visibility: hidden;
                            opacity: 0;
                            position: absolute;
                            top: 140%;
                            left: 50%;
                            margin-left: em(-90);
                            width: em(150);
                            background-color: #293955;
                            color: #fff;
                            text-align: center;
                            padding: em(7) em(15) em(6) em(15);
                            border-radius: em(6);
                            z-index: 200;
                            //transition: opacity 0.3s;
                            font-size: em(14);
                            line-height: 1.71;

                            span {
                                opacity: 0.54;
                            }

                            &:before {
                                content: '';
                                width: 0;
                                height: 0;
                                border-left: 9px solid transparent;
                                border-right: 9px solid transparent;
                                border-bottom: 9px solid #293955;
                                position: absolute;
                                top: em(-10);
                                left: 0;
                                right: 0;
                                margin: 0 auto;
                            }
                        }

                        img {
                            width: 2.2em;
                            height: 2.2em;
                            -webkit-border-radius: 50%;
                            -moz-border-radius: 50%;
                            border-radius: 50%;
                            border: 3px solid #095de3;
                            -webkit-backface-visibility: hidden;
                            -ms-transform: translateZ(0);
                            /* IE 9 */
                            -webkit-transform: translateZ(0);
                            /* Chrome, Safari, Opera */
                            transform: scale(1) translateZ(0);
                            transition: all .4s;
                            filter: none;
                            -webkit-filter: blur(0px);
                            -moz-filter: blur(0px);
                            -ms-filter: blur(0px);
                            filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='0');
                        }

                        a {
                            display: inline-block;
                            line-height: 0;
                            position: relative;
                            transition: all .4s;

                            &:hover {

                                div.member-info {
                                    visibility: visible;
                                    opacity: 1;
                                }

                                img {
                                    transform: scale(1.3);
                                    transition: transform .4s;
                                    filter: none;
                                    -webkit-filter: blur(0px);
                                    -moz-filter: blur(0px);
                                    -ms-filter: blur(0px);
                                    filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='0');
                                }
                            }
                        }
                    }
                }
            }
        }

        .testimonial-left {
            width: 31.25%;
            position: relative;
            top: -6.5em;
            left: 2em;

            .inner {
                top: 13%;
                left: 11%;
            }

            @media(max-width: $sd-max) {
                top: em(-60);
                left: em(20);
            }

            @media(max-width: $sd-max) {
                top: em(-50);
            }

            @media(max-width: $xs-max) {
                width: 30%;
                top: 0;
                left: 0;
                margin-top: em(20);
                margin-bottom: em(20);
                font-size: em(20);
            }

            @media(max-width: $xxs-max) {
                width: 100%;
            }
        }

        .testimonial-center {
            width: 35.09%;
            margin-top: em(60);
            position: relative;


            @media(max-width: $sd-max) {
                margin-top: em(50);
            }

            @media(max-width: $xs-max) {
                width: 60%;
                margin-top: 0;
                margin-bottom: em(20);
                font-size: em(22);
            }

            @media(max-width: $xxs-max) {
                width: 100%;
            }
        }

        .testimonial-right {
            width: 31.92%;
            position: relative;

            top: -6.5em;
            right: 2em;

            @media(max-width: $sd-max) {
                top: em(-60);
                right: em(35);
            }

            @media(max-width: $sd-max) {
                top: em(-40);
                right: em(20);
            }

            @media(max-width: $xs-max) {
                width: 60%;
                top: 0;
                right: 0;
                margin-bottom: em(20);
                font-size: em(20);
            }

            @media(max-width: $xxs-max) {
                width: 100%;
            }
        }
    }
}

// Freelancer section
.freelancer_section {
    text-align: center;
    position: relative;
    padding: em(180) 0 em(120) 0;
    background: url('../images/background/freelancer-bg.svg');
    background-position: -20% 30%;
    background-size: 30% 30%;
    background-repeat: no-repeat;

    @media(max-width: $md-max) {
        padding: em(100) 0 em(80) 0;
    }

    .freelancer_cont {
        width: 55.55%;
        margin: 0 auto;
        padding: em(30) em(20);
        border-radius: em(30.8);
        box-shadow: 0 5px 25px 5px rgba(212, 225, 248, .43);
        z-index: 10;

        background: rgb(255, 255, 255);
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 69%, rgba(233, 238, 247, 1) 100%);
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 69%, rgba(233, 238, 247, 1) 100%);
        background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 69%, rgba(233, 238, 247, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#e9eef7', GradientType=0);

        @media(max-width: $md-max) {
            width: 70%;
        }

        @media(max-width: $xs-max) {
            width: 80%;
        }

        .freelancer_steps {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            margin: em(55) 0 em(20) 0;

            @media(max-width: $xs-max) {
                flex-flow: column wrap;
                padding: 0 em(20);
            }

            .step {
                width: 25%;
                margin: 0 2%;

                @media(max-width: $xs-max) {
                    width: 100%;
                    margin: 0 0 em(30) 0;

                    &:last-child {
                        margin: 0;
                    }
                }
            }

            .step1 {
                img {
                    width: em(73);
                }
            }

            .step2 {
                img {
                    width: em(64);
                }
            }

            .step3 {
                img {
                    width: em(68);
                }
            }
        }
    }

    .freelancer_illustration {
        background-image: url(../images/webiz_freelancer-illu.png);
        background-position: center bottom;
        background-size: cover;
        background-repeat: no-repeat;

        z-index: -99;
        padding-top: 30%;
        position: relative;
        width: 60%;
        margin: 0 auto;
        top: em(-1);

        @media(max-width: $md-max) {
            padding-top: 32%;
            width: 65%;
        }

        @media(max-width: $xs-max) {
            padding-top: 40%;
            width: 80%;
        }

        @media(max-width: $xxs-max) {
            padding-top: 50%;
            width: 100%;
        }
    }

    .freelancer_bg {
        position: absolute;
        top: 58%;
        z-index: -999;
        background-image: url(../images/webiz_freelancer_bg.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 170% 100%, auto;
        width: 100%;
        padding-top: 45%;

        @media(max-width: $md-max) {
            top: 60%;
            padding-top: 60%;
        }

        @media(max-width: $xs-max) {
            top: 60%;
            padding-top: 110%;
        }

        @media(max-width: $xxs-max) {
            top: 72%;
        }
    }

    @include bp(phone) {
        padding: 0;
    }
}

// CTA section
.cta_section {
    margin-top: 4em;
    padding-top: 40.1%;
    background-image: url(../images/webiz_cta_bg.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    .cta_cont {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
        width: 38.25%;
        padding-top: em(50);

        @media(max-width: $xs-max) {
            width: 80%;
        }

        h2 {
            font-size: em(36);
            line-height: 0.86;
            letter-spacing: em(-0.9);
            display: inline-block;
            position: relative;
            margin-bottom: 0;

            img {
                position: absolute;
                right: -2em;
                top: -1.5em;
            }
        }

        h3 {
            margin: em(20) 0 em(25) 0;
        }
    }

    @include bp(phone) {
         margin-top: 0;
    }
}

// Footer section
footer {
    background-color: $color_white;

    .top_foot {
        .wrapper-inner {
            padding-top: em(30);
            padding-bottom: em(30);
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-between;

            @media(max-width: $xs-max) {
                flex-flow: column wrap;
                text-align: center;
            }
        }

        a.back-to-top {
            width: 20%;
            line-height: 0;

            @media(max-width: $sm-max) {
                width: 15%;
            }

            @media(max-width: $xs-max) {
                width: 100%;
                padding: em(10) 0;
            }

        }

        ul {
            padding: 0;
            display: flex;
            flex-flow: row nowrap;
            width: 80%;

            @media(max-width: $sm-max) {
                width: 90%;
            }

            @media(max-width: $xs-max) {
                width: 100%;
                flex-flow: row wrap;
                margin-top: em(30);
            }

            li {
                list-style-type: none;

                @media(max-width: $xs-max) {
                    margin-bottom: em(10);
                }

                a {
                    font-size: em(18);
                    letter-spacing: em(-0.5);
                    font-weight: 600;
                    color: $color_text-screen;
                    padding: 0 em(20);

                    @media(max-width: $sm-max) {
                        padding: 0 em(10);
                    }

                    &:hover {
                        color: $color_dark_blue;
                    }
                }
            }
        }

        ul.nav {
            justify-content: center;
        }

        ul.social-links {
            width: 20%;
            justify-content: flex-end;
            line-height: 0;

            @media(max-width: $sm-max) {
                width: 15%;
            }

            @media(max-width: $xs-max) {
                width: 100%;
                justify-content: center;
                margin: em(10) 0 0 0;
            }

            a:hover {
                svg {}
            }
        }
    }

    .bottom_foot {
        background-color: #1f2838;

        .wrapper-inner {
            padding-top: em(20);
            padding-bottom: em(20);

            font-size: em(13);

            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            @media(max-width: $xs-max) {
                flex-wrap: wrap;
            }
        }

        p.copyright {
            width: 33.33%;

            @media(max-width: $xs-max) {
                width: 50%;
                order: 2;
            }
        }

        div.foot_logo {
            width: 33.33%;
            text-align: center;

            @media(max-width: $xs-max) {
                width: 100%;
                order: 1;
                margin: 20px 0 10px 0;
            }

            img {
                width: em(136);
            }
        }

        .made_in_info {
            width: 33.33%;
            text-align: right;

            @media(max-width: $xs-max) {
                width: 50%;
                order: 3;
            }
        }
    }
}

// CONTACT FORM
#contactForm {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(37, 50, 123, .6);

    .contact-inner {
        &:before {
            content: '';
            left: 20px;
            width: 100px;
            height: 100px;
            position: absolute;
            background: url(../images/background/freelancer-bg.svg);
            background-position: center center;
            background-size: 100%;
            background-repeat: no-repeat;
        }

        h2 {
            margin-bottom: .2em;
        }

        box-shadow: 0 5px 25px 5px rgba(212, 225, 248, .43);
        z-index: 10;

        background: rgb(255, 255, 255);
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 69%, rgba(233, 238, 247, 1) 100%);
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 69%, rgba(233, 238, 247, 1) 100%);
        background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 69%, rgba(233, 238, 247, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#e9eef7', GradientType=0);

        padding: 2em;
        border-radius: 10px;
        width: 500px;
        text-align: center;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);

        .last-row {
            float: right;
            margin: 1.4em 0;
            width: 45%;
        }

        .first-row {
            float: left;
            margin: 1.4em 0;
            width: 45%;
        }

        .full-row {
            width: 97%;
            margin: 1.4em 0;
        }
        .g-recaptcha {
            width: 65%;
            margin: 1em auto;

        }


        input,
        textarea {
            display: block;
            width: 100%;
            padding: 0;
            color: $color_text_heading;
            border-width: 0;
            padding: .5em;
            margin: 0 auto;
            border: none;
            border-bottom: 1px solid #757575;

            &::placeholder {
                color: $color_text-screen;
            }
        }

        textarea:focus,
        input:focus {
            outline: none;
            border-bottom: 1px solid $color_dark_blue;
            transition: 0.2s ease all;
        }


        .btn {
            margin: 0 auto;
            margin-top: 2em;
            color: white;
        }

        .btn-close {
            display: block;
            position: fixed;
            cursor: pointer;
            right: 10px;
            top: 10px;
            width: 32px;
            height: 32px;
            transition: transform .25s ease-in-out;
            -webkit-backface-visibility: hidden;
            -webkit-transform: translateZ(0) scale(1.0, 1.0);

            &:hover {
                transform: rotate(90deg);

            }

            &:before {
                content: "";
                position: absolute;
                display: block;
                margin: auto;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                width: 25px;
                height: 0;
                border-top: 2px solid $color_dark_blue;
                transform: rotate(45deg);
                transform-origin: center;
            }

            &:after {
                content: "";
                position: absolute;
                display: block;
                margin: auto;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                width: 25px;
                height: 0;
                border-top: 2px solid $color_dark_blue;
                transform: rotate(-45deg);
                transform-origin: center;
            }
        }


    }

    @include bp(phone) {
        .contact-inner {
            width: 80%;
            margin: 0 auto;
            &:before {
                display: none;
            }
        }
    }

}