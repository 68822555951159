.testimonials_cont {
    .testimonial-single {
        text-align: left;
        background-size: contain;
        background-position: top center;
        background-repeat: no-repeat;
        width: 30%;

        .inner {
            position: relative;
            font-size: .9em;
            width: 70%;
            position: relative;
            padding: 0;
            margin: 5em auto;
            font-size: .9em;
        }

        h3 {
            color: $color_white;
            margin: 0 0 10px 0;
        }

        h4 {
            margin: 0 0 10px 0;
        }

        p {
            color: $color_white;
            margin-top: 0;
        }

        &.left {
            background-image: url('../images/testimonial-left.png');
            height: 100%;
            width: 31.25%;
            position: relative;
            top: -6.5em;
            left: 2em;

            .inner {
                margin: 4em auto;
                margin-left: 3em;
            }
            @include bp(tablet) {
                width: 100%;
                top: 0;
                left: 0;
                .inner {
                    width: 40%;
                    margin: 6em auto;
                }
            }

            @include bp(phone) {
            	width: 100%;
	            top: 0;
	            left: 0;
	            .inner {
	            	width: 70%;
	            	margin: 6em auto;
	            }
            }
        }

        &.center {
            background-image: url('../images/testimonial-center.png');
            width: 35.09%;
            height: 100%;
            margin-top: em(60);
            position: relative;

            .inner {
                margin-left: 6em;
            }
            @include bp(tablet) {
                width: 100%;
                top: 0;
                left: 0;
                .inner {
                    width: 30%;
                    margin: 6em auto;
                }
            }

            @include bp(phone) {
            	width: 100%;
	            margin-top: 0;
	            .inner {
	            	 width: 70%;
	            	 margin: 6em auto;
                     padding-left: 5em;
	            }
            }
        }

        &.right {
            background-image: url('../images/testimonial-right.png');
            width: 31.92%;
            height: 100%;
            position: relative;

            top: -6.5em;
            right: 2em;

            .inner {
                margin-left: 6em;
            }
            @include bp(tablet) {
                width: 100%;
                top: 0;
                left: 0;
                .inner {
                    width: 30%;
                    margin: 6em auto;
                }
            }

            @include bp(phone) {
            	width: 100%;
	            top: 0;
	            right: 0;
	            .inner {
	            	width: 70%;
	            	margin: 6em auto;
                    padding-left: 5em;
	            }
            }
        }

        .team-members {
            display: flex;
            flex-flow: row nowrap;
            align-items: stretch;

            >span {
                color: $color_white;
                opacity: 0.4;
                font-size: em(14);
                line-height: 1.29;
                letter-spacing: em(-0.2);
                position: relative;
                top: em(11);
            }

            ul {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                margin: 0;
                padding: 0 0 0 em(15);
                min-height: em(40);

                li {
                    list-style-type: none;
                    z-index: 0;
                    &:nth-child(2) {
                        position: relative;
                        left: em(-12);
                        z-index: 0;
                    }

                    &:last-child {
                        position: relative;
                        left: em(-20);
                        z-index: 0;
                    }

                    &:hover {
                        z-index: 100;
                    }

                    .member-info {
                        visibility: hidden;
                        opacity: 0;
                        position: absolute;
                        top: 140%;
                        left: 50%;
                        margin-left: em(-90);
                        width: em(150);
                        background-color: #293955;
                        color: #fff;
                        text-align: center;
                        padding: em(7) em(15) em(6) em(15);
                        border-radius: em(6);
                        z-index: 200;
                        //transition: opacity 0.3s;
                        font-size: em(14);
                        line-height: 1.71;

                        span {
                            opacity: 0.54;
                        }

                        &:before {
                            content: '';
                            width: 0;
                            height: 0;
                            border-left: 9px solid transparent;
                            border-right: 9px solid transparent;
                            border-bottom: 9px solid #293955;
                            position: absolute;
                            top: em(-10);
                            left: 0;
                            right: 0;
                            margin: 0 auto;
                        }
                    }

                    img {
                        width: 2.2em;
                        height: 2.2em;
                        -webkit-border-radius: 50%;
                        -moz-border-radius: 50%;
                        border-radius: 50%;
                        border: 3px solid #095de3;
                        -webkit-backface-visibility: hidden;
                        -ms-transform: translateZ(0);
                        /* IE 9 */
                        -webkit-transform: translateZ(0);
                        /* Chrome, Safari, Opera */
                        transform: scale(1) translateZ(0);
                        transition: all .4s;
                        filter: none;
                        -webkit-filter: blur(0px);
                        -moz-filter: blur(0px);
                        -ms-filter: blur(0px);
                        filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='0');
                    }

                    a {
                        display: inline-block;
                        line-height: 0;
                        position: relative;
                        transition: all .4s;

                        &:hover {

                            div.member-info {
                                visibility: visible;
                                opacity: 1;
                            }

                            img {
                                transform: scale(1.3);
                                transition: transform .4s;
                                filter: none;
                                -webkit-filter: blur(0px);
                                -moz-filter: blur(0px);
                                -ms-filter: blur(0px);
                                filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='0');
                            }
                        }
                    }
                }
            }
        }
    }
}