/* --- Media Queries --- */
@mixin bp($point) {

	@if $point == phone{
		@media (max-width: 599px) {
			@content;
		}
	}

	@if $point == portrait {
		@media (max-width: 670px){
			@content;
		}
	}

	@else if $point == tablet {
		@media (min-width: 600px) and (max-width: 980px){
			@content;
		}
	}

	@else if $point == mobile {
		@media (max-width: 980px){
			@content;
		}
	}

	@else if $point == ipad {
		@media (min-width: 981px) and (max-width: 1200px){
			@content;
		}
	}

	@else if $point == laptop {
		@media (max-width: 1280px) and (min-width: 981px), (max-height: 800px){
			@content;
		}
	}

	@else if $point == desktop {
		@media (min-width: 980px){
			@content;
		}
	}

	@else if $point == large {
		@media (min-width: 1680px){
			@content;
		}
	}

	@else if $point == slarge {
		@media (min-width: 2100px){
			@content;
		}
	}
}

