/*!
Theme Name: Webiz
Theme URI:
Author: Webiz Team
Author URI: http://webiz.team
Description:
Version: 1.0.0
License:
License
Text Domain:
Tags:

*/

@import "breakpoints";
@import "responsive";
@import "variables";
@import "flexgrid";
@import "mixins";
@import "normalize";
@import "elements";
@import "global";
@import "testimonials";

